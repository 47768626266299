import React from "react";
import { useTranslation } from "react-i18next";
import bekobod from "../../assets/images/bekobod.webp";

const RegionInfo = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex justify-center items-center px-[100px] gap-8 h-[400px]">
        <div className="w-[600px]">
          <img src={bekobod} alt="" />
        </div>
        <div className="w-[865px] h-[170px]">
          <h4 className="font-[700] mb-[10px] mt-[-50px] text-[36px] text-#121221">
            {t("Bekobod tumani haqida")}
          </h4>
          <p>
            <strong className="text-[20px]">Bekobod tumani</strong> — Toshkent
            viloyatidagi tuman. 1926-yil 29-sentabrda tashkil etilgan (1962-yil
            24-dekabrda Sirdaryo viloyati Yangiyer tumaniga qoʻshib yuborilgan,
            1963-yil 17-aprelda qayta tuzilgan).Shimolida Toshkent viloyatining
            Boʻka tumani, gʻarbda Sirdaryo viloyati, sharq va janubida
            Tojikiston Respublikasi bilan chegaradosh. Maydoni 0,76 ming km².
            Aholisi 127,7 ming kishi (2000). Bekobod tumanida 1 shaharcha (Zafar
            va Dalvarzin) va 12 qishloq kengashi (Bahoriston, Bekobod, Guliston,
            Jumabozor, Madaniyat, Mehnatobod, Oxunboboyev, Pushkin, Chanoq,
            Yangi hayot, Qiyot) bor. Markazi - Zafar shaharchasi
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegionInfo;
