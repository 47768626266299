import React from "react";
import gerb from "../../../assets/images/gerb.png";
import karta from "../../../assets/images/karta.png";
import ScrollToTop from "./scrollToTop/ScrollToTop";
import { FiInstagram, FiYoutube } from "react-icons/fi";
import { FaTelegramPlane } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="lg:h-[400px] md:h-[400px] bg-[#002b5b] text-white">
      <div className="max-w-[1280px] mx-auto lg:flex md:flex justify-between pt-4 lg:px-0 md:px-3 px-4">
        <div className="lg:w-[650px] md:w-[600px] flex flex-col gap-4 lg:text-[17px] md:text-[16px]">
          <div className="flex gap-2 items-center pt-[6px]">
            <img src={gerb} alt="gerb" width="50px" height="50px" />
            <h2 className="text-xl font-semibold text-white">
              {t("Bekobod tumani hokimligi")}
            </h2>
          </div>
          <p className="lg:w-[600px]">
            {t(
              "Sayt Toshkent viloyati hokimligi huzuridagi, Axborot kommunikatsiya texnologiyalarini rivojlantirish markazi mutaxassislari tomonidan yaratilgan © 2023"
            )}
          </p>
          <p className="lg:w-[550px]">
            {t(
              "Ushbu sayt materiallaridan foydalanganda www.bekobod.uz saytiga havola qilish majburiydir"
            )}
          </p>
          <a className="hover:text-gray-400 duration-300" href="/saytxaritasi">
            {t("Sayt xaritasi")}
          </a>
          <div className="flex gap-6 mt-8">
            <a
              className="hover:text-gray-400 duration-300"
              href="https://www.instagram.com/Bekobodtumanhokimligi/"
              target="_blank"
            >
              <FiInstagram size={26} />
            </a>
            {/* <a
              className="hover:text-gray-400 duration-300"
              href="https://www.youtube.com/@buka_tuman_hokimligi"
              target="_blank"
            >
              <FiYoutube size={29} />
            </a> */}
            <a
              className="hover:text-gray-400 duration-300"
              href="https://t.me/Bekobodtumanihokimligi"
              target="_blank"
            >
              <FaTelegramPlane size={28} />
            </a>
            <a
              className="hover:text-gray-400 duration-300"
              href="https://www.facebook.com/bekobodtumanhokimligi/"
              target="_blank"
            >
              <BsFacebook size={26} />
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-[10px]">
          <p>{t("Bizning manzil:")}</p>
          <p>{t("Zafar shaharchasi, Mustaqqillik ko'chasi 1-uy")}</p>
          <a
            href="https://www.google.com/maps/place/%D0%91%D0%B5%D0%BA%D0%B0%D0%B1%D0%B0%D0%B4%D1%81%D0%BA%D0%B8%D0%B9+%D1%80%D0%B0%D0%B9%D0%BE%D0%BD,+%D0%A3%D0%B7%D0%B1%D0%B5%D0%BA%D0%B8%D1%81%D1%82%D0%B0%D0%BD/@40.3792956,69.242819,15.99z/data=!4m6!3m5!1s0x38b1e8c576b0f35b:0xe39db137d48a7203!8m2!3d40.3687486!4d69.2400734!16s%2Fm%2F012mf_bv?entry=ttu"
            target="_blank"
            className="overflow-hidden bg-no-repeat bg-cover"
          >
            <img
              src={karta}
              alt="harita"
              a
              width="400px"
              height="400px"
              className="hover:scale-125 object-cover transition duration-700 ease-in-out"
            />
          </a>
          <p>
            {t("Tel: +998 70 953 13 50")} <br />
          </p>
          <p>{t("Murojaat qilish vaqti: 9:00 / 18:00")}</p>
        </div>
      </div>

      <div className="lg:block md:block hidden">
        <ScrollToTop />
      </div>
    </div>
  );
};

export default Footer;
