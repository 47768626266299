import { useGetAllNews } from "../../../hooks/query";
import { GoCalendar } from "react-icons/go";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Pagination } from "@mantine/core";
import { Loader } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import { useTranslation } from "react-i18next";

const AllNewsList = () => {
  const { t } = useTranslation();
  const [page, setPages] = useState(1);
  const useGetAllNewsList = useGetAllNews({
    page: page,
  });
  const navigate = useNavigate();

  if (useGetAllNewsList.isLoading) {
    return (
      <div className="flex justify-center h-[700px]">
        <Loader color="#FF7D00" size="xl" variant="bars" />
      </div>
    );
  }
  return (
    <div className="lg:mx-0 md:mx-0 mx-6">
      <button
        onClick={() => navigate(-1)}
        className="lg:flex items-center gap-1 bg-[#15616D] text-white pr-4 px-3 rounded py-1 hover:bg-[#FF7D00] transition-all duration-200 md:flex hidden"
      >
        <TiArrowBack size={26} />
        {t("ortga")}
      </button>
      <h3 className="text-center my-[30px] text-2xl font-semibold">
        {t("Barcha yangiliklar")}
      </h3>
      <div>
        {useGetAllNewsList.data?.results.map((item) => {
          let date = new Date(item.add_time);
          let dateMDY = `${date.getDate()}-${
            date.getMonth() + 1
          }-${date.getFullYear()}`;
          return (
            <div key={item.id}>
              <div className="lg:flex gap-4 mb-[20px] lg:w-[900px]">
                <img
                  src={item.post_img}
                  alt="post image"
                  width={350}
                  height={250}
                />
                <div>
                  <Link to={`/pdetail/${item.slug}`}>
                    <h4 className="font-semibold text-xl lg:w-full w-[360px]">
                      {item.title}
                    </h4>
                    <p className="lg:w-full w-[360px]">{item.subtitle}</p>
                  </Link>
                  <span className="flex items-center gap-1 text-[16px] mt-[35px] justify-end lg:w-full w-[350px]">
                    <GoCalendar />
                    {dateMDY}
                  </span>
                </div>
              </div>
              <div className="border-b-2 border-[#0f3460] pt-[10px] mb-[34px] lg:w-full w-[360px]"></div>
            </div>
          );
        })}
      </div>
      <Pagination
        py={28}
        color="cyan"
        size="lg"
        radius="xl"
        page={page}
        onChange={setPages}
        disabled={page >= 10}
        total={useGetAllNewsList.data?.total_pages}
      />
    </div>
  );
};

export default AllNewsList;
