import React from "react";
import { useGetNavbar } from "../../../hooks/query";
import { Link } from "react-router-dom";

const NavbarItem = ({ setOpened }) => {
  const useGetNavbarList = useGetNavbar();
  console.log(useGetNavbarList);

  return (
    <div className="lg:grid lg:grid-cols-5 gap-4 mx-[20px]">
      {useGetNavbarList.data?.map((item) => (
        <div>
          <h5 className="font-bold my-[20px] text-xl">{item.name}</h5>
          {item.children.map((subItem) => (
            <Link
              onClick={() => setOpened(false)}
              to={`/ndetail/${subItem.slug}`}
              className=" duration-300"
            >
              <li className="hover:bg-[#449bda] hover:text-white duration-300 px-2">{subItem.name}</li>
            </Link>
          ))}
        </div>
      ))}
    </div>
  );
};

export default NavbarItem;
