import { useGetMarkaziyApt } from "../../hooks/query";
import MarkaziyAprtItem from "./MarkaziyAprtItem";
import { useNavigate } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import { useTranslation } from "react-i18next";

const MarkaziyAprt = () => {
  const useGetMarkaziyAptList = useGetMarkaziyApt();
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="mb-[40px]">
      <button
        onClick={() => navigate(-1)}
        className="lg:flex md:flex hidden md:ml-4  items-center gap-1 bg-[#15616D] text-white pr-4 px-3 rounded py-1 hover:bg-[#FF7D00] transition-all duration-200"
      >
        <TiArrowBack size={26} />
        {t("ortga")}
      </button>
      <h3 className="text-center my-[20px] text-3xl font-semibold">
        {t("Markaziy apparat")}
      </h3>
      {useGetMarkaziyAptList.data?.results.map((item) => (
        <MarkaziyAprtItem item={item} key={item.id} />
      ))}
    </div>
  );
};
export default MarkaziyAprt;
