import { Loader } from "@mantine/core";
import React from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";

import { request } from "../../services/service-api";

const Search = () => {
  const { search } = useLocation();

  const { isLoading, data } = useQuery({
    queryKey: ["gigs", search],
    queryFn: () =>
      request.get(`/main/post/search/get/${search}`).then((res) => {
        return res.data;
      }),
  });
  console.log("🚀 ~ file: Search.jsx:18 ~ Search ~ data:", data);

  return (
    <div className="min-h-[60vh]">
      {data?.map((item) => (
        <div key={item.id} className="py-4 hover:text-[#56a0d4] duration-700">
          <Link to={`/${item.slug}`}>{item.title}</Link>
        </div>
      ))}
      {data?.length === 0 && "Ma'lumot topilmadi !"}
      {isLoading && (
        <div className="flex justify-center">
          <Loader size="xl" />
        </div>
      )}
    </div>
  );
};

export default Search;
